export const lightModeTheme = 'light';
export const darkModeTheme = 'dracula';

export const PHONE_REG_EXP =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/;
export const PIN_REG_EXP = /^\d{4}$/;
export const EMAIL_REG_EXP = /@[^.]*\./;
export const PAGE_SIZE_DEFAULT = 20;

export const SORT_ASCENDING = "0";
export const SORT_DESCENDING = "1";

//Axios errors
export const ERR_FR_TOO_MANY_REDIRECTS = 'ERR_FR_TOO_MANY_REDIRECTS';
export const ERR_BAD_OPTION_VALUE = 'ERR_BAD_OPTION_VALUE';
export const ERR_BAD_OPTION = 'ERR_BAD_OPTION';
export const ERR_NETWORK = 'ERR_NETWORK';
export const ERR_DEPRECATED = 'ERR_DEPRECATED';
export const ERR_BAD_RESPONSE = 'ERR_BAD_RESPONSE';
export const ERR_BAD_REQUEST = 'ERR_BAD_REQUEST';
export const ERR_CANCELED = 'ERR_CANCELED';
export const ECONNABORTED = 'ECONNABORTED';
export const ETIMEDOUT = 'ETIMEDOUT';

//RTK QUERY errors
export const FETCH_ERROR = 'FETCH_ERROR';

export const ERROR_ARRAY = [
  ERR_FR_TOO_MANY_REDIRECTS,
  ERR_BAD_OPTION_VALUE,
  ERR_BAD_OPTION,
  ERR_NETWORK,
  ERR_DEPRECATED,
  ERR_BAD_RESPONSE,
  ERR_BAD_REQUEST,
  ERR_CANCELED,
  ECONNABORTED,
  ETIMEDOUT,
  FETCH_ERROR,
];

export const STORAGE_ASSET_ALLOWED_IMAGE_TYPES = ["image/jpg", "image/jpeg", "image/png", "image/webp"];
export const STORAGE_ASSET_ALLOWED_DOCUMENT_TYPES = ["image/jpg", "image/jpeg", "image/png", "image/webp", "application/pdf", "application/x-pdf"];

export const AuthForms = Object.freeze({
  LOGIN: 0,
  REGISTER_WITH_EMAIL: 1,
  SEND_OTP: 3,
  ENTER_OTP: 4,
  FORGOT_PASSWORD: 5,
  RESET_PASSWORD: 6
});

export const RateTimeSlots = Object.freeze({
  START_TIME_SLOT: 0,
  END_TIME_SLOT: 1
});

export const PricingTypes = Object.freeze({
  HIREFEEONLY: 0,
  CUSTOMPRICING: 1,
  PACKAGESONLY: 2
});

export const RateTypes = Object.freeze({
  DEPOSIT: -1,
  HIREFEE: 0,
  MINIMUM_SPEND: 1,
  PACKAGE_PER_PERSON: 2,
  HIRE_FEE_AND_MINIMUM_SPEND: 3,
  HIRE_FEE_AND_PACKAGE_PER_PERSON: 4,
});

export const RatePeriods = Object.freeze({
  PER_HOUR: 0,
  PER_SESSION: 1,
  PER_MORNING: 2,
  PER_AFTERNOON: 3,
  PER_EVENING: 4,
  PER_DAY: 5,
});


export const DaysOfWeek = Object.freeze({
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6
});

export const ModerationStatuses = Object.freeze({
  IN_PROGRESS: 0,
  REJECTED: 500,
  READY_FOR_REVIEW: 1000,
  PENDING_REVIEW: 2000,
  APPROVED: 3000,
});

export const PublishingStatuses = Object.freeze({
  DRAFT: 0,
  PUBLISHED: 1000
});

export const SeasonTypes = Object.freeze({
  RATES: 0,
  PACKAGES: 1
});

export const CancellationPolicies = Object.freeze({
  VERY_FLEXIBLE: 0,
  FLEXIBLE: 1,
  STADARD_30_DAY: 2,
  STADARD_60_DAY: 3,
  CUSTOM: 4,
});

export const SpaceWorkflowSteps = Object.freeze({
  OVERVIEW: { key: 0, value: "edit-space" },
  PHOTOS: { key: 1, value: "edit-photos" },
  CAPACITY: { key: 2, value: "edit-capacity" },
  FEATURES: { key: 3, value: "edit-features" },
  SEARCH_KEYWORDS: { key: 4, value: "edit-keywords" },
  PRICE_AND_OPEN_HOURS: { key: 5, value: "edit-pricing" },
  PACKAGES: { key: 6, value: "edit-packages" },
  BOOKING_OPTIONS: { key: 7, value: "edit-rules" }
});


export const VenueWorkflowSteps = Object.freeze({
  OVERVIEW: { key: 0, value: "edit-overview" },
  LOCATION: { key: 1, value: "edit-location" },
  CATERING: { key: 2, value: "edit-catering" },
  DETAILS: { key: 3, value: "edit-details" },
  SPACES: { key: 4, value: "edit-spaces" },
  BUSINESS_DETAILS: { key: 5, value: "edit-business-details" },
  TERMS_AND_CONDITIONS: { key: 6, value: "terms-and-conditions" },
  REVIEW: { key: 7, value: "review" },
});


export const Countries = Object.freeze({
  SOUTH_AFRICA: 248,
});

export const CompanyTypes = Object.freeze({
  NATURAL_PERSONS: 1,
});

export const StorageAssetLinkTypes = Object.freeze({
  STORAGE_ASSET_LINK_TYPE_PACKAGE: 0,
  STORAGE_ASSET_LINK_TYPE_SPACE: 1,
  STORAGE_ASSET_LINK_TYPE_VENUE: 2,
  STORAGE_ASSET_LINK_TYPE_FLOOR_PLAN: 3,
  STORAGE_ASSET_LINK_TYPE_ID_DOCUMENTATION: 4,
  STORAGE_ASSET_LINK_TYPE_PROOF_OF_ADDRESS: 5,
  STORAGE_ASSET_LINK_TYPE_BANK_DETAILS: 6,
  STORAGE_ASSET_LINK_TYPE_COMPANY_TRUST_DOCUMENTS: 7,
  STORAGE_ASSET_LINK_TYPE_LOCATION_ENTITY: 9,
});

export const VenueSteps = Object.freeze({
  STORAGE_ASSET_LINK_TYPE_PACKAGE: 0,
  STORAGE_ASSET_LINK_TYPE_SPACE: 1,
  STORAGE_ASSET_LINK_TYPE_VENUE: 2,
  STORAGE_ASSET_LINK_TYPE_FLOOR_PLAN: 3,
  STORAGE_ASSET_LINK_TYPE_ID_DOCUMENTATION: 4,
  STORAGE_ASSET_LINK_TYPE_PROOF_OF_ADDRESS: 5,
  STORAGE_ASSET_LINK_TYPE_BANK_DETAILS: 6,
  STORAGE_ASSET_LINK_TYPE_COMPANY_TRUST_DOCUMENTS: 7,
});

export const DeviceTypes = Object.freeze({
  MOBILE: "mobile",
  TABLET: "tablet",
  DESKTOP: "desktop"
});

export const ViewSpaceFeatures = Object.freeze({
  CATERING_PRIMARY: 0,
  CATERING_SECONDARY: 1,
  FACILITIES: 2,
  ACCOMMODATION: 3,
  PARKING: 4,
  MUSIC_SOUND: 5,
  ACCESSIBILITY: 6,
  RULES: 7
});

export const ROLES = Object.freeze({
  ADMINISTRATOR: "Administrator",
  STAFF: "Staff",
});

export const SouthAfrica = Object.freeze({
  lat: -26.26,
  lng: 28.09,
});

export const CapeTown = Object.freeze({
  lat: -33.9266,
  lng: 18.4187,
});

export const SearchLocationSourceTypes = Object.freeze({
  LOCAL: 0,
  PLACES: 1,
  MAP: 2,
});

export const MapTypeIds = Object.freeze({
  HYBRID: 'hybrid',
  ROADMAP: 'roadmap',
  SATELLITE: 'satellite',
  TERRAIN: 'terrain'
});



